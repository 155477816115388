<template>
  <div class="programDetailPage">
    <base-section v-if="isLoading">
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <skeleton-loader-card
              image-height="240px"
              class="programDetailPage__loader"
            />
          </base-column>
        </base-row>

        <br />

        <base-row>
          <base-column
            v-for="n in 4"
            v-bind:key="n"
            modifiers="sm-3"
          >
            <skeleton-loader-card />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <div v-else>
      <base-section>
        <program-detail-header
          v-bind:program="currentProgram"
          class="programDetailPage__header"
        />
      </base-section>

      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseSection from '@/components/grid/BaseSection';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import SkeletonLoaderCard from '@/components/SkeletonLoaderCard';
import ProgramDetailHeader from '@/components/program/ProgramDetailHeader';

export default {
  components: {
    BaseSection,
    BaseGrid,
    BaseRow,
    BaseColumn,
    SkeletonLoaderCard,
    ProgramDetailHeader,
  },

  data() {
    return {
      isLoading: true,
    };
  },

  computed: {
    ...mapGetters('program', ['currentProgram']),
  },

  watch: {
    $route(to, from) {
      if (to.params.periodId && to.params.periodId !== from.params.periodId) {
        this.fetchData();
      }
    },
  },

  created() {
    const { query, params } = this.$route;

    if (!!query.workout && !params.workoutId) {
      this.$router.replace({ query: null });
    }

    this.fetchData();
  },

  beforeUnmount() {
    this.$store.commit('program/unsetCurrentProgram');
    this.$store.commit('period/unsetCurrentPeriod');
  },

  methods: {
    async fetchData() {
      const { programId, periodId } = this.$route.params;

      this.isLoading = true;

      await this.$store.dispatch('program/fetch', programId);
      await this.$store.dispatch('period/fetch', periodId);

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programDetailPage {
  @media print {
    display: none;
  }
}

.programDetailPage__loader {
  margin-top: rem(32px);
}

.programDetailPage__header {
  margin: 0 0 rem(16px) 0;

  @include desktop {
    margin: 0 0 rem(32px) 0;
  }
}
</style>
