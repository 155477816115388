<template>
  <div
    v-bind:style="cssVars"
    class="programDetailHeader"
  >
    <base-grid modifiers="maxWidth">
      <base-row>
        <base-column>
          <div class="hero">
            <div class="hero-title">
              <span class="hero-title__trainer">
                {{ program.name }}
              </span>

              <span
                v-if="program.trainers[0]">
              >
                {{ $t('programCard.withTrainer', { trainer: program.trainers[0].name }) }}
              </span>
            </div>

            <div class="hero-image" />

            <div class="hero-selectbox">
              <Selectbox
                v-if="periods.length"
                v-bind:options="periods"
                v-bind:selected="$route.params.periodId"
                v-model="selectedPeriod"
              />
            </div>
          </div>

          <navigation-children v-bind:children="navigationChildren" />
        </base-column>
      </base-row>
    </base-grid>
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import Selectbox from '@/components/Selectbox';
import BaseHeading from '@/components/BaseHeading';
import NavigationChildren from '@/components/NavigationChildren';
import getCategoryTheme from '@/utils/getCategoryTheme';
import { ModalBus } from '@/eventBus';

const ProgramLevelModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/program/ProgramLevelModal' /* webpackChunkName: "programLevelModal" */),
}));

const ProgramDetailSettings = markRaw(defineAsyncComponent({
  loader: () => import('@/components/program/ProgramDetailSettings' /* webpackChunkName: "programDetailSettings" */),
}));

export default {
  components: {
    BaseGrid,
    BaseRow,
    BaseColumn,
    NavigationChildren,
    BaseHeading,
    Selectbox,
  },

  props: {
    program: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedPeriod: this.$route.params.periodId,
    };
  },

  watch: {
    selectedPeriod(newValue) {
      this.$router.push({
        name: this.$route.name,
        params: { periodId: newValue },
      });
    },
  },

  computed: {
    periods() {
      if (!this.program.id) {
        return [];
      }

      return this.program.periods.map(({ id }, index, array) => ({
        name: this.$t('programCard.periodXofY', { x: index + 1, y: array.length }),
        value: id,
      }));
    },

    categoryTheme() {
      const categorySlug = this.program.categories[0]?.slug || 'fit';
      const categoryTheme = getCategoryTheme(categorySlug);

      return categoryTheme;
    },

    cssVars() {
      if (!this.program.id) {
        return {};
      }

      return {
        '--program-detail-bg-image': `url('${this.categoryTheme.programDetailBackgroundImage}')`,
        '--program-detail-header-image': `url('${this.program.image}?w=1000&q=80')`,
      };
    },

    navigationChildren() {
      const { programId, periodId } = this.$route.params;
      const routerParams = { programId, periodId };

      return [
        {
          to: { name: 'programWorkout', params: routerParams },
          name: this.$t('navigation.programWorkout'),
        },
        {
          to: { name: 'programRecipes', params: routerParams },
          name: this.$t('navigation.programRecipes'),
        },
        {
          name: this.$t('navigation.programLevel'),
          callback: () => {
            ModalBus.emit('open', {
              component: ProgramLevelModal,
            });
          },
        },
        {
          name: this.$t('navigation.programSettings'),
          callback: () => {
            ModalBus.emit('open', {
              component: ProgramDetailSettings,
            });
          },
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

.programDetailHeader {
  .navigation-children {
    justify-content: left !important;
    margin: 0 -#{$gridGutterWidth / 2};

    @media (min-width: $breakpoint-md) {
      justify-content: center !important;
      margin: 0;
      border-radius: 0 0 $border-radius-md $border-radius-md !important;
    }
  }
}

.hero-selectbox {
  select {
    background-color: $color-white;
    color: $color-text;
  }

  .svg-inline--fa {
    color: $color-text;
  }
}
</style>

<style lang="scss" scoped>
@import "@/scss/lib";

$program-detail-header-height-mobile: 192px;
$program-detail-header-height-desktop: 240px;

.programDetailHeader {
  min-height: $program-detail-header-height-mobile;

  @media (min-width: $breakpoint-md) {
    min-height: $program-detail-header-height-desktop;
    padding-top: $spacing-md;
    background: transparent repeat-x var(--program-detail-bg-image);
    background-position-x: rem(2500px);
    background-position-y: top;
  }
}

.hero {
  position: relative;
  margin: 0 -#{$gridGutterWidth / 2};
  z-index: 2;

  @include desktop {
    margin: 0;
  }
}

.hero-title {
  position: absolute;
  bottom: $spacing-sm;
  left: $spacing-sm;
  margin-bottom: 0;
  color: $color-white;

  @media (min-width: $breakpoint-md) {
    bottom: $spacing-md;
    left: $spacing-md;
  }

  .hero-title__trainer {
    @include heading-2;

    @include desktop {
      margin: 0 rem(12px) 0 0;
    }
  }

  span {
    @include lead;
    display: block;

    @media (min-width: $breakpoint-md) {
      display: inline-block;
    }
  }
}

.hero-image {
  background: no-repeat var(--program-detail-header-image) center center;
  background-size: cover;
  height: $program-detail-header-height-mobile;

  @media (min-width: $breakpoint-md) {
    border-radius: $border-radius-md $border-radius-md 0 0;
    height: $program-detail-header-height-desktop;
  }
}

.hero-selectbox {
  bottom: 1.25rem;
  display: inline-block;
  position: absolute;
  right: $spacing-sm;
  width: 10rem;

  @media (min-width: $breakpoint-md) {
    bottom: 1rem;
  }

  select {
    background-color: $color-white;
    color: $color-text;
  }

  .svg-inline--fa {
    color: $color-text;
  }
}
</style>
